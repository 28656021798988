import { TransformControls } from './Controls/TransformControls'
import { EditorControls } from './Controls/EditorControls'
import * as THREE from 'three'

function tools(scope,onDragCallback) {
    const canv = scope.renderer.domElement
    const editorControls = new EditorControls(scope.cameras.orbit, canv)
    //console.log(editorControls)

    //transform controls setup
    const transformControls = new TransformControls(scope.cameras.orbit, canv, scope);
    transformControls.enabled = true;
    scope.overlay.add(transformControls)
    canv.addEventListener('mousedown', onMouseDown, false)

    transformControls.addEventListener('mouseDown', () => {
        editorControls.enabled = false
    })

    transformControls.addEventListener('mouseUp', () => {
        const design = scope.environment.design
        const selected = scope.environment.selected
        const design_selected = design.components.find(comp => comp.ref === selected.ref)
        design_selected.position = {...selected.position}
        design_selected.rotation = {x: selected.rotation.x, y: selected.rotation.y, z: selected.rotation.z}
        //scope.storage.send(design)      
        editorControls.enabled = true
    })

    //collision updates

    function onDragStart() {
        canv.addEventListener("mousemove", onDrag, false)
        transformControls.addEventListener("mouseUp", onDragStop, false)      
    }

    function resetMeshDisplay() {
        for (const object of scope.models.children) {
            for (const collisionMesh of object.userData.collisionMeshes) {
                for (const child of collisionMesh.children) {
                    child.material.color.set(0x00ff00)
                    child.material.transparent = true
                    child.material.opacity = 0.3
                    child.material.metalness = 0
                    child.visible = false
                }
            }
        }
    }

    function updateMeshDisplay(collisions) {
        for (const collider of collisions) {
            
            if (collider[0].name === "SFZ" && collider[1].name === "SFZ") {
                continue
            }
            if (collider[0].name === "FMZ_R1" && collider[1].name === "FMZ_R1") {
                continue
            }
            if ((collider[0].name === "FMZ_R1" && collider[1].name === "FMZ_R2") || (collider[0].name === "FMZ_R2" && collider[1].name === "FMZ_R1")) {
                continue
            }
            for (const collidee of collider) {
                for (const child of collidee.children) {
                    child.material.color.set(0xff0000)
                    child.material.transparent = true
                    child.material.opacity = 0.3
                    child.material.metalness = 0
                    child.visible = true
                }
            }
        }
    }

    function onDrag() {
        resetMeshDisplay()      
        scope.physics.updateCollisionMeshes(transformControls.object)
        const collisions = scope.physics.filterCollisions(scope.physics.getCollisions())
        updateMeshDisplay(collisions);
        if (typeof onDragCallback === 'function')
          onDragCallback();
    }

    function onDragStop() {
        canv.removeEventListener("mousemove", onDrag)
        transformControls.removeEventListener("mouseup", onDragStart)
    }

    transformControls.addEventListener("mouseDown", onDragStart, false)







    //selection editor stuff etc

    var onDownPosition = new THREE.Vector2();
    var onUpPosition = new THREE.Vector2();
    
    function onMouseDown( event ) {

      var array = getMousePosition( canv, event.clientX, event.clientY );
      onDownPosition.fromArray( array );
  
      document.addEventListener( 'mouseup', onMouseUp, false );
  
    }
  
    function onMouseUp( event ) {
  
      var array = getMousePosition( canv, event.clientX, event.clientY );
      onUpPosition.fromArray( array );
  
      handleClick();
  
      document.removeEventListener( 'mouseup', onMouseUp, false );
  
    }
  
    function getMousePosition( dom, x, y ) {
  
      var rect = dom.getBoundingClientRect();
      return [ ( x - rect.left ) / rect.width, ( y - rect.top ) / rect.height ];
  
    }
  
    function handleClick() {
  
        if ( onDownPosition.distanceTo( onUpPosition ) === 0 ) {
  
            var intersects = getIntersects( onUpPosition, scope.models.children );
            //console.log(intersects)
  
            if ( intersects.length > 0 ) {
  
                var object = intersects[ 0 ].object;
                object = getTop(object)
                //console.log(object)
  
                if ( object.userData.object !== undefined ) {
  
                    // helper
                    //console.log(object.userData.object)
                    //editor.select( object.userData.object );
  
                } else {
                    transformControls.attach(object)
                    scope.environment.selected = object
                    //editor.select( object );
  
                }
  
            } else {
                scope.environment.selected = null
                transformControls.detach()
                //editor.select( null );
  
            }
  
            //render();
  
        }
  
    }
  
    var raycaster = new THREE.Raycaster();
    var mouse = new THREE.Vector2();
  
    function getIntersects( point, objects ) {
  
        mouse.set( ( point.x * 2 ) - 1, - ( point.y * 2 ) + 1 );
  
        raycaster.setFromCamera( mouse, scope.cameras.selected );
  
        return raycaster.intersectObjects( objects, true );
  
    }
    
  

    function getTop(object) {
        if (object.parent !== scope.models) {
          return getTop(object.parent)
        }
        else {
          return object
        }
      }
    

    return {
        selected: 0,
        select(tool)  {
            if (this.selected >=0) {
                this.tools[this.selected].disable()
            }
            this.selected = tool
            this.tools[tool].enable()
        },
        toggle2D3D() {
            if (scope.cameras.selected === scope.cameras.orbit) {
                scope.cameras.selected = scope.cameras.tudi
                const tudi = scope.cameras.tudi
                tudi.left = -scope.host.clientWidth/50
                tudi.right = scope.host.clientWidth/50
                tudi.bottom = -scope.host.clientHeight/50
                tudi.top = scope.host.clientHeight/50
                transformControls.camera = scope.cameras.selected
            } else {
                scope.cameras.selected = scope.cameras.orbit
                transformControls.camera = scope.cameras.selected
                editorControls.enabled = true
            }
        },
        collision: {
            updateMeshDisplay,
            resetMeshDisplay
        },
        tools: {
            0: {
                name: 'Orbit',
                enable() {
                    editorControls.enabled = true
                },
                disable() {
                    editorControls.enabled = false
                }
            },
            1: {
                name: 'Translate',
                enable() {
                    //transformControls.camera = scope.cameras.selected
                    transformControls.enabled = true
                    transformControls.setMode('translate')
                    editorControls.enabled = true
                },
                disable() {
                    transformControls.enabled = false
                    //transformControls.visible = false
                    editorControls.enabled = false
                }
            },
            2: {
                name: 'Rotate',
                enable() {
                    //transformControls.camera = scope.cameras.selected
                    transformControls.enabled = true
                    transformControls.setMode('rotate')
                    editorControls.enabled = true
                },
                disable() {
                    transformControls.enabled = false
                    //transformControls.visible = false
                    editorControls.enabled = false
                }
            }
        },
        getTools() {
            let returnTools = [];
            for (const tool in this.tools) {
                returnTools.push({id: tool, name: this.tools[tool].name});
            }
            return returnTools;
        }
    }
}

export default tools