import React from 'react';
import { AmplifyAuthenticator , AmplifySignIn, AmplifySignUp, AmplifyConfirmSignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {Auth} from 'aws-amplify';

import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './Pages/Home';
import Admin from './Pages/Admin'
import AR from './Pages/AR'

const My404Component = ({ location }) => (
  <div>
    <h5>
      404 - Not found  
    </h5>
  </div>
);



function App() {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {

    if (authState === undefined) {
      Auth.currentAuthenticatedUser().then(authData => {
        setAuthState(AuthState.SignedIn);
        setUser(authData);
      }).catch(() => { });
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      console.log(authData);
      setUser(authData);
    });
  }, []);

  return  ((authState === AuthState.SignedIn) && user) ? (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/admin" exact >
          <Admin user={user} />
        </Route>
        <Route path="/ar" exact component={AR} />
        <Route path="/sharedesign/:desid" component={Home} />
        <Route component={My404Component} />
      </Switch>
    </Router>
  ) : (<div className="AuthContainer" >
  <AmplifyAuthenticator usernameAlias='email' >
  <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            label: "Email address",
            placeholder: "Enter your email address...",
            required: true,
          },
          {
            type: "given_name",
            label: "Given name(s)",
            placeholder: "Enter given name(s) here...",
            required: true,
          },
          {
            type: "family_name",
            label: "Surname",
            placeholder: "Enter surname here...",
            required: true,
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Please choose a password...",
            required: true,
          }
        ]} 
      />
    <AmplifySignIn slot="sign-in"  usernameAlias='email' hideSignUp={false} ></AmplifySignIn>
    <AmplifyConfirmSignUp usernameAlias='email' headerText="Please check your email for verification code" slot="confirm-sign-up"></AmplifyConfirmSignUp>
  </AmplifyAuthenticator>
  </div>)

}

export default App;
