import React from 'react'

const style = {
    //backgroundColor: '#00ff00',
    userSelect: 'none',
    height: '100%'
}





function Window({editor}) {
    const canvasEl = React.useRef(null);

    React.useEffect(() => {
        console.log('Editor mounted', canvasEl);
        window.addEventListener('resize', handleWindowResize);

        editor.mount(canvasEl.current);
        return () => {
            console.log('Editor unmounted');
            editor.unmount();
        }
    }, []);

    const handleWindowResize = () => {
        editor.handleWidthHeightUpdate();
    };
    

    return (
        <div ref={canvasEl} style={style} id='3dcanvas' />
    )
}

export default Window