import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { green } from '@material-ui/core/colors';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import HistoryIcon from '@material-ui/icons/History';
import {API} from 'aws-amplify';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import {Collapse, List, ListItemSecondaryAction} from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 100,
        margin: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: 'center'
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));



export default function SavedDesignsList({ editor, loadDesign ,deleteDesign }) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [designs, setDesigns] = React.useState([]);
    const [designsOpen, setDesignsOpen] = React.useState(true);

    React.useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            const res = await API.get('ModuplayAPI', '/client/designs', {
                queryStringParameters: {  // OPTIONAL
                    id: ''
                },
            });
            //console.log(res);
            setDesigns(res);
            setLoading(false);
        };
        loadData();
    }, []);


    const handleClick2 = () => {
        setDesignsOpen(!designsOpen);
      };

    const handleDeleteDesign = async (ind) => {
        try {
            const desId=designs[ind].id;
            if (window.confirm(`Are you sure you want to remove design ID #${desId} ? \nNote this action cannot be undone.`)) {
                await deleteDesign(Number(desId));
                const newDesignsArr = designs.filter((e, i) => (i !== ind));
                setDesigns(newDesignsArr);
            }
        } catch (err) {
            alert('An error occured when tried to remove this design');

        }

    }


    return (
        <>
            <ListItem button onClick={handleClick2}>
                <ListItemIcon>
                    <HistoryIcon />

                </ListItemIcon>
                <ListItemText primary="Saved designs" />
                {designsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={designsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                   {loading ? (<><LinearProgress style={{marginTop: '10px'}} /> <LinearProgress color="secondary" style={{marginTop: '10px'}}/></>) : designs.map( (el,ind) =>
                    <ListItem key={ind} button className={classes.nested} onClick={() => loadDesign(el)}>
                        <ListItemIcon>
                            <AssignmentIcon />
                           </ListItemIcon>
                           <ListItemText primary={'#' + el.id + ' - ' + el.name} />
                           <ListItemSecondaryAction>
                               <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDesign(ind)}>
                                   <DeleteForeverIcon color='secondary' />
                               </IconButton>
                           </ListItemSecondaryAction>
                    </ListItem>)}
                </List>
            </Collapse>
        </>
    );
}
