import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { Auth} from 'aws-amplify';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Button from '@material-ui/core/Button';
import { makeStyles  } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    logoImg: {
        maxHeight: '60px',
        marginRight: theme.spacing(1)
    },

    title: {
        flexGrow: 1,
        fontFamily: '"VAG Rounded LT W04 Bold" !important',
        textTransform: 'uppercase'
    },

}));



export default function Header() {
    const classes = useStyles();

    return( <AppBar position="absolute" className={clsx(classes.appBar)}>
    <Toolbar className={classes.toolbar}>
      <img className={classes.logoImg} src="/mplogo.png" alt='Moduplay Pty Ltd' />
      <Typography variant="h6" color="inherit" noWrap className={classes.title}>
        Moduplay 3D Configurator
      </Typography>
        <Button variant="contained" color="secondary" onClick={()=>Auth.signOut()}>
          Sign out
        </Button>
      <IconButton color="inherit">
        <Badge badgeContent={3} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Toolbar>
  </AppBar>);

}

