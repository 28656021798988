import React from 'react';
import clsx from 'clsx';
import { makeStyles ,ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import {Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {  SecondaryListItems } from '../Components/Menu';
import {RibbonToolbar} from '../Components/Menu/RibbonToolbar';
import {API} from 'aws-amplify';
import Window from '../Components/Window/Window';
import Editor from '../lib/Editor/Editor';
import ModelMediaCard from '../Components/Models/ModelMediaCard';
import SavedDesignsList from '../Components/Models/SavedDesignsList';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ModelLoader } from '../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import  {useParams} from "react-router-dom";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Header from '../Components/Menu/Header';
import {theme} from '../Style/Theme';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


function TabPanel(props) {
  const { children, value, index, hidden, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      style={{display: ((value !== index) || hidden) ? 'none' : 'flex'}}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {(
        <React.Fragment>
          {children}
        </React.Fragment>
      )}
    </div>
  );
}


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const editor = new Editor();
window.editor = editor;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" target="_blank" href="https://moduplay.com.au/">
        Moduplay Pty Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 370;



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  ribbonToolbar: {
    backgroundColor: '#2A2E33',
    color: 'white'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontFamily: '"VAG Rounded LT W04 Bold" !important',
    textTransform: 'uppercase'
  },
  drawerPaper: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  modelDrawerPaper: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9)
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight:  theme.spacing(10),
    paddingLeft: theme.spacing(10)
  },
  paper: {
    padding: '5px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'CALC(100vh - 250px)',
  },
  tabsContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    height: 'CALC(100vh - 128px)'
  },
  tabs: {
    height: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Roboto',
    textTransform:'uppercase'
  },
  catHeader: {
   backgroundColor: theme.palette.primary.main,
   color: 'white'
  },
  modelsWrapper: {
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'flex-start' /* NEW */
  },
  categoryWrapper: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1)
  },
  leftTabPanel: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 'CALC(100% - 3rem)'
  },
  logoImg: {
    maxHeight: '60px',
    marginRight: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  tabVertFlexContainer: {
    flexDirection: 'column',
    height:'100%'
  },
  rotated: {
    transform: 'rotate(-90deg)',
    fontSize: theme.typography.pxToRem(12),
  },
  rotatedNowrap: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(12),
  },
  tabbtn : {
    flex: 1,
    maxWidth: '2.7rem !important',
    minWidth: '1.7rem !important'
  },
  marginbottom: {
    marginBottom: theme.spacing(2)
  }
}));

const StyledAccordionSummary = ({cat_id, categories}) => {
  const classes=useStyles();
  return (<AccordionSummary
    expandIcon={<ExpandMoreIcon style={{color:'white'}} />}
    aria-controls="panel1a-content"
    id={"cat-accord" + cat_id}
    className={classes.catHeader}
  >
    <Typography className={classes.heading}>{categories.find((c) => (c.id === Number(cat_id))).name}</Typography>
  </AccordionSummary>)
}

export default function Home() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modelsDrawerOpen, setModelsDrawerOpen] = React.useState(false);
  const [playmodes, setPlaymodes] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [pmodeCategoryProduct, setPmodeCategoryProduct] = React.useState({});
  const [allProds, setAllProds] = React.useState({});// map of key-val for all products
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState('');
  const [currentDesignModels,setcurrentDesignModels] = React.useState([]);
 // const [savedDesigns,setSavedDesigns] = React.useState([]);
  const [dlopen, setDlOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [layout,setLayout] = React.useState('box');
  const [layoutLength,setLayoutLength] = React.useState(0);
  const [layoutWidth,setLayoutWidth] = React.useState(0);
  const [layoutRadius,setLayoutRadius] = React.useState(0);
  const [designName, setDesignName]=  React.useState('');
  const [currentDesign, setCurrentDesign]=  React.useState({});
  const [fov, setFov]=  React.useState(30);
  const [shareDlOpen, setShareDlOpen] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState('https://');
  const [settingsDlOpen, setSettingsDlOpen] = React.useState(false);
  const {desid} = useParams();
  const shareTextAreaRef = React.useRef(null);
  const [copySuccess, setCopySuccess] = React.useState('');
  const [measurements, setMeasurements] = React.useState({area:0, perimeter:0});
  const [tabValue, setTabValue] = React.useState(0);
  const [leftTabValue, setLeftTabValue] = React.useState(0);
  const [ancillaryModeIdx, setAncillaryModeIdx]= React.useState(0);
  const [allMaterials, setAllMaterials]= React.useState([]);
  const [softFallId, setSoftFallId]= React.useState(0);
  const [edgeId, setEdgeId]= React.useState(0);


    React.useEffect(() => {
      //console.log('desid:',desid);
      const loadData = async () => {
        const materials = await API.get('ModuplayAPI', '/client/materials');
        console.log(materials);
        setAllMaterials(materials);
        const pms = await API.get('ModuplayAPI', '/client/playmodes');
        console.log(pms);
        setPlaymodes(pms);
        setAncillaryModeIdx(pms.findIndex((pm) => (pm.name.match(/ancillaries/i))));
        const cats = await API.get('ModuplayAPI', '/client/categories');
        console.log(cats);
        setCategories(cats);
        const allproducts = await API.get('ModuplayAPI', '/client/products', {
          queryStringParameters: {  // OPTIONAL
            q: '',
          },
        });
        const categorisedProducts = {};
        const mappedProds = {};
        console.log(allproducts);
        allproducts.forEach(element => {
          mappedProds[element.code] = element;
          const pmode = cats.find((c) => (c.id === element.category_id)).playmode_id;
          if (!(categorisedProducts[pmode])) {
            categorisedProducts[pmode] = {};
            categorisedProducts[pmode][element.category_id] = [element.code];
          }
          else {
            if (!categorisedProducts[pmode][element.category_id])
              categorisedProducts[pmode][element.category_id] = [];
            categorisedProducts[pmode][element.category_id].push(element.code);
          }
        });
        console.log(categorisedProducts);
        console.log(Object.keys(categorisedProducts));
        setPmodeCategoryProduct(categorisedProducts);
        setAllProds(mappedProds);
        if (Number(desid)) {
          setDlOpen(false);
          setLoading(true);
          const res = await API.get('ModuplayAPI', '/client/designs', {
            queryStringParameters: {  // OPTIONAL
              id: Number(desid)
            }
          });
          const designToLoad = res[0];
          await loadDesign(designToLoad, mappedProds);
        } else {
          setDlOpen(true);
        }
      };
      loadData();
      editor.designUpdateCallback = (data) => {
        //console.log(data);
        setMeasurements(Object.assign({}, data));
      };
    }, []);

  const handleShareDlClose =() =>{
    setShareDlOpen(false);
  }

  const handleCopyToCB = (e) =>{
    //console.log(shareUrl);
      shareTextAreaRef.current.select();
      document.execCommand('copy');
      e.target.focus();
      setCopySuccess('Copied!');
  }

  const handleDrawerOpen = () => {
    setOpen(true);
    setTimeout(() => editor.handleWidthHeightUpdate(),500);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setTimeout(() => editor.handleWidthHeightUpdate(),500);
  };

  const showAlert = (message) =>{
    setSnackMsg(message);
    setSnackOpen(true);

  }

  const handleLayoutChange = (event) => {
    setLayout(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const addModelToCurrentDesign = (product,gltf) => {
    editor.processAndAddModel({ id: product.code }, gltf,true); // position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 } }, gltf); removed because causing issues with new models
    setcurrentDesignModels(prevModels => {
      return [...prevModels, product];
    });
    showAlert('Model successfully loaded.');
  }

  const removeModelFromCurrentDesign = (index) => {
   // const toBeRemoved = currentDesignModels[index];
    editor.removeModelFromDesign(index);
    const newDesignsArr= currentDesignModels.filter((e,ind) => (ind !== index ));
    setcurrentDesignModels(newDesignsArr);
  }

  const loadDesign = async (designObject,productsArray) => {
    try {

    //console.log('cd ', designObject);
    const allMappedProds = (productsArray ? productsArray : allProds);
    //console.log(productsArray);
    //console.log(allMappedProds);
    setDlOpen(false);
    setLoading(true);
    setCurrentDesign(designObject);
    console.log(designObject.metadata);
    setSoftFallId(designObject.metadata?.softFallId || 0);
    setEdgeId(designObject.metadata?.edgeId || 0);
    setShareUrl(window.location.protocol+'//'+window.location.host+'/sharedesign/'+designObject.id);
    const gltfs=[];
    
    for (const elem of designObject.scenedata.components)
    {
      const objectKey=allMappedProds[elem.id].gltf_object_key;
      setcurrentDesignModels(prevModels => {
        return [...prevModels, allMappedProds[elem.id]];
      });

      const gltf = await ModelLoader(objectKey);
      gltfs.push(gltf);
    }

    editor.loadDesign(designObject,gltfs);
    setLoading(false);
    showAlert('Design loaded successfully.');

    } catch (err) {
      setLoading(false);
      alert(err);
      console.log(err);

    }

  }

  const saveDesign = async () => {
    try {
      setLoading(true);
      const scene_data = editor.getCurrentDesign();
      const reqBody = {  // OPTIONAL
        id: currentDesign.id,
        name: currentDesign.name,
        products: currentDesignModels.map((prod) => (prod.code)),
        sceneData: scene_data,
        metadata: currentDesign.metadata
      };
      //console.log('Saving...', reqBody);
      const res = await API.patch('ModuplayAPI', '/client/designs', {
        body: reqBody
      });
      //console.log(res);
      setCurrentDesign(res);
      setLoading(false);
      showAlert('Design saved successfully.');
    }
    catch (err) {
      setLoading(false);
      alert('Unauthorised to save design; make sure you are the creator of this design.');
    }

  }

  const deleteDesign = async (designId) => {
    try {
      setLoading(true);
      const reqBody = {  // OPTIONAL
        id: designId
      };
      console.log('Deleting...', reqBody);
      await API.del('ModuplayAPI', '/client/designs', {
        body: reqBody
      });
      setLoading(false);
      showAlert('Design deleted successfully.');
    }
    catch (err) {
      setLoading(false);
    }

  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


  const openSettingsModal = () => {
    setSettingsDlOpen(true);
  };

  const handleDlClose = () => {
    setDlOpen(false);
  };


  const handleSettingsDlClose =() => {
    setSettingsDlOpen(false);
  
  }

  const handleSetSettings =() => {
    editor.handleSetSceneSetting({fov: Number(fov)});
    setSettingsDlOpen(false);
  }

  const handleOpenShareModal = () =>{
    setCopySuccess('');
    setShareDlOpen(true);
  }

  const handleCreate = async () => {
    try {
      if (!designName)
      {
        window.alert('Please enter a design name first.');
        return;
      }
      setLoading(true);
      const scene_data = editor.createNewDesign({ type: layout, dims: ((layout === 'auto') ? {} : ((layout === 'box') ? { width: Number(layoutLength), depth: Number(layoutWidth) } : { radius: layoutRadius } ))});
      const res = await API.put('ModuplayAPI', '/client/designs', {
        body: {  // OPTIONAL
          name: designName,
          products: [],
          sceneData: scene_data
        }
      });
      //console.log(res);
      setCurrentDesign(res);
      setShareUrl(window.location.protocol+'//'+window.location.host+'/sharedesign/'+res.id);
      setDlOpen(false);
      
    }
    catch (err) {
       window.alert('An error occured when creating a new design.');
       console.log(err);
    }finally {
      setLoading(false);
    }

  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Dialog
          open={dlopen}
          onClose={handleDlClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">{"Create new design ... "}</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3} >
              <Grid item xs={8}>
                <Paper elevation={5} style={{ padding: '10px' }}>
                  <TextField
                    label="Design name"
                    id="outlined-start-adornment"
                    className={classes.textField}
                    variant="outlined"
                    value={designName}
                    onChange={(ev) => setDesignName(ev.target.value)}
                  />

                  <FormControl component="fieldset" style={{ marginTop: '10px' }}>
                    <FormLabel component="legend">Layout</FormLabel>
                    <RadioGroup aria-label="Layout" name="layout" value={layout} onChange={handleLayoutChange} row>
                      <FormControlLabel value="box" control={<Radio />} label="Box" />
                      <FormControlLabel value="circle" control={<Radio />} label="Circle" />
                      <FormControlLabel value="auto" control={<Radio />} label="Auto Detect" />
                    </RadioGroup>
                  </FormControl>
                  {layout === 'box' ? (<><TextField
                    label="Length"
                    id="outlined-start-adornment"
                    className={classes.textField}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    variant="outlined"
                    value={layoutLength}
                    onChange={(ev) => setLayoutLength(ev.target.value)}
                  />
                    <TextField
                      label="Width"
                      id="outlined-start-adornment"
                      className={classes.textField}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                      }}
                      variant="outlined"
                      value={layoutWidth}
                      onChange={(ev) => setLayoutWidth(ev.target.value)}
                    /></>) : (layout === 'circle' && (<TextField
                      label="Radius"
                      id="outlined-start-adornment"
                      className={classes.textField}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">m</InputAdornment>,
                      }}
                      variant="outlined"
                      value={layoutRadius}
                      onChange={(ev) => setLayoutRadius(ev.target.value)}
                    />))}
                  <DialogActions>
                    {/* <Button onClick={handleDlClose} color="primary">
              Cancel
          </Button> */}
                    <Button onClick={handleCreate} variant="contained" color="primary" disabled={loading}>
                      Create
                    </Button>
                  </DialogActions>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <SavedDesignsList editor={editor} loadDesign={loadDesign} deleteDesign={deleteDesign} />
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>

        </Dialog>
        <Dialog
          open={loading}
          // onClose={handleDlClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">{"Loading ... "}</DialogTitle>
          <DialogContent dividers >
            <CircularProgress color="secondary" size={100} />
          </DialogContent>

        </Dialog>

        <Dialog
          open={settingsDlOpen}
          onClose={handleSettingsDlClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">{"Scene adjustments "}</DialogTitle>
          <DialogContent dividers >
            <TextField
              label="Field of View (Degrees)"
              id="outlined-start-adornment"
              className={classes.textField}
              variant="outlined"
              value={fov}
              onChange={(ev) => setFov(ev.target.value)}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleSetSettings} variant="contained" color="primary" >
              Done
            </Button>
          </DialogActions>

        </Dialog>


        <Dialog
          open={shareDlOpen}
          onClose={handleShareDlClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">{"Share design "}</DialogTitle>
          <DialogContent dividers >
            <TextField
              label="Share Link"
              id="outlined-start-adornment"
              className={classes.textField}
              variant="outlined"
              value={shareUrl}
              inputRef={shareTextAreaRef}
              InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="copy to clipboard"
                    onClick={handleCopyToCB}
                    title='Copy to clipboard'
                    edge="end"
                  >
                    <FileCopyIcon />

                  </IconButton>
                </InputAdornment>
              }}

            />
            {copySuccess}

          </DialogContent>
          <DialogActions>
            <Button onClick={handleShareDlClose} variant="contained" color="primary" >
              Done
            </Button>
          </DialogActions>

        </Dialog>


        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackOpen}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert severity="success">
            {snackMsg}
          </Alert>
        </Snackbar>
        <CssBaseline />
        <Header />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Drawer
            variant="permanent"
            anchor="left"
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
          >
            <div className={classes.appBarSpacer} />
            <div className={classes.toolbarIcon} >
              {open ? <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton> : <IconButton onClick={handleDrawerOpen}>
                <ChevronRightIcon />
              </IconButton>}
            </div>
          <Box className={classes.tabsContainer} >


            <TabPanel value={leftTabValue} index={0} className={classes.leftTabPanel} id={`vertical-tabpanel-1`} hidden={!open}>
              <List>

                <SecondaryListItems drawerOpen={open} models={currentDesignModels} onModelRemove={removeModelFromCurrentDesign}  measurements={measurements} currentDesign={currentDesign} allMaterials={allMaterials} setLeftTabValue={setLeftTabValue} />
              </List>

            </TabPanel>
            <TabPanel value={leftTabValue} index={1} className={classes.leftTabPanel} id={`vertical-tabpanel-anc`} hidden={!open} >

            {(open && playmodes && playmodes[ancillaryModeIdx] && pmodeCategoryProduct[playmodes[ancillaryModeIdx].id]) && Object.keys(pmodeCategoryProduct[playmodes[ancillaryModeIdx].id]).map((cat_id, ind) => (<Accordion key={cat_id} defaultExpanded={true}>
                      <StyledAccordionSummary cat_id={cat_id} categories={categories} />
                      <AccordionDetails className={classes.modelsWrapper}>
                        {pmodeCategoryProduct[playmodes[ancillaryModeIdx].id][cat_id].map((prodCode, ind) => (
                          <ModelMediaCard key={prodCode} editor={editor} product={allProds[prodCode]} addModelToDesign={addModelToCurrentDesign} />
                        ))}

                      </AccordionDetails>
                    </Accordion>))}

            </TabPanel>
            <TabPanel value={leftTabValue} index={2} className={classes.leftTabPanel}   id={`vertical-tabpanel-sftfall`} hidden={!open} >
            {allMaterials && <Box style={{flexDirection:'column', display:'flex'}}>
            <FormControl variant="outlined" className={classes.marginbottom} fullWidth>
                  <InputLabel id="softfall-materail-1">Soft Fall</InputLabel>
                  <Select
                    labelId="softfall-materail-1"
                    id="select-category-outlined"
                    value={softFallId}
                    onChange={(ev)=>{
                      const newId=Number(ev.target.value);
                      setSoftFallId(newId);
                      const desMeta=currentDesign.metadata;
                      console.log(desMeta);
                      const updatedDesign={...currentDesign, metadata: {...desMeta, "softFallId": newId}}
                      setCurrentDesign(updatedDesign);
                    }}
                    label="Soft fall"
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    {allMaterials.filter((m)=>(m.material_type === 0)).map((mat)=>(
                      <MenuItem key={mat.id} value={mat.id}>{`${mat.title} - $${mat.price}/m2` }</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.marginbottom} fullWidth>
                  <InputLabel id="softfall-materail-1">Edge/border material </InputLabel>
                  <Select
                    labelId="softfall-materail-1"
                    id="select-category-outlined"
                    value={edgeId}
                    onChange={(ev)=> {
                      const newId=Number(ev.target.value);
                      setEdgeId(newId);
                      const desMeta=currentDesign.metadata;
                      console.log(desMeta);
                      const updatedDesign={...currentDesign, metadata: {...desMeta, "edgeId": newId}}
                      setCurrentDesign(updatedDesign);
                    }}
                    label="Edge/border"
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    {allMaterials.filter((m)=>(m.material_type === 1)).map((mat)=>(
                      <MenuItem key={mat.id} value={mat.id}>{`${mat.title} - $${mat.price}/m` }</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Box>}

            </TabPanel>
            <Tabs
              orientation="vertical"
              value={leftTabValue}
              onChange={(event, newValue) => {
                console.log(newValue);
                setLeftTabValue(newValue);
              }}
              aria-label="All products"
              className={classes.tabs}
              classes={{
                flexContainerVertical: classes.tabVertFlexContainer,
              }}
            >
              <Tab label={<span className={classes.rotatedNowrap}>Costing</span>} id={`vertical-tab-1`} aria-controls={`vertical-tabpanel-1`} value={0} className={classes.tabbtn} />
              <Tab label={<span className={classes.rotatedNowrap}>Ancillaries</span>} id={`vertical-tab-2`} aria-controls={`vertical-tabpanel-anc`} value={1} className={classes.tabbtn} />
              <Tab label={<span className={classes.rotatedNowrap}>Soft fall</span>} id={`vertical-tab-3`} aria-controls={`vertical-tabpanel-sftfall`} value={2} className={classes.tabbtn} />

            </Tabs>
            </Box>


          </Drawer>
          <Container maxWidth="xl" className={classes.container}>
          <RibbonToolbar className={classes.ribbonToolbar}  editor={editor} openSettingsModal={openSettingsModal} saveDesign={saveDesign} openShareModal={handleOpenShareModal}/>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={fixedHeightPaper} style={{padding: '0px', overflow:'hidden'}}>
                  <Window editor={editor} />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                {currentDesign.name &&
                  <Paper className={classes.paper}>
                    <Typography variant="subtitle1" >
                      {currentDesign.name + ' (ID: ' + currentDesign.id + ')'}
                    </Typography>
                    <Typography variant="subtitle2" >
                      {'Last saved at ' + (new Date(currentDesign.last_updated)).toString()}
                    </Typography>
                  </Paper>
                }
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
            <Drawer
              variant="permanent"
              anchor="right"
              classes={{
                paper: clsx(classes.modelDrawerPaper, !modelsDrawerOpen && classes.drawerPaperClose),
              }}
              open={modelsDrawerOpen}
            >
              <div className={classes.appBarSpacer} />
              <div className={classes.toolbarIcon} style={{ justifyContent: 'flex-start' }}>
                {modelsDrawerOpen ? <IconButton onClick={() => setModelsDrawerOpen(false)}>
                  <ChevronRightIcon />
                </IconButton> : <IconButton onClick={() => setModelsDrawerOpen(true)}>
                  <ChevronLeftIcon />
                </IconButton>}
              </div>
              <Paper className={classes.tabsContainer}>

                <Tabs
                  orientation="vertical"
                  value={tabValue}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setTabValue(newValue);}}
                  aria-label="All products"
                  className={classes.tabs}
                  classes={{
                    flexContainerVertical: classes.tabVertFlexContainer, 
                  }}
                >
                {playmodes && playmodes.filter((pm,idx)=>(idx !== ancillaryModeIdx)).map((pl, ind) => (
                  <Tab key={pl.id} label={<span className={classes.rotated} >{pl.name}</span>} id={`vertical-tab-${ind}`} aria-controls={`vertical-tabpanel-${ind}`} value={ind} className={classes.tabbtn} />
                ))}

                </Tabs>
                {modelsDrawerOpen && playmodes && playmodes.filter((pm,idx)=>(idx !== ancillaryModeIdx)).map((pl, ind) => (
                  <TabPanel key={pl.id} value={tabValue} index={ind} className={classes.categoryWrapper} id={`vertical-tabpanel-${ind}`}>
                    {pmodeCategoryProduct[pl.id] && Object.keys(pmodeCategoryProduct[pl.id]).map((cat_id, ind) => (<Accordion key={cat_id} defaultExpanded={true}>
                      <StyledAccordionSummary cat_id={cat_id} categories={categories} /> 
                      <AccordionDetails className={classes.modelsWrapper}>
                        {pmodeCategoryProduct[pl.id][cat_id].map((prodCode, ind) => (
                          <ModelMediaCard key={prodCode} editor={editor} product={allProds[prodCode]} addModelToDesign={addModelToCurrentDesign} />
                        ))}

                      </AccordionDetails>
                    </Accordion>))}
                  </TabPanel>
                ))}

              </Paper>
            </Drawer>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
}