import MuseoLightFont from '../fonts/MuseoSans_500-webfont.woff';
import VAGBoldFont from '../fonts/053b2ed5fefef3c90b4c7defba66132d.woff2';
import { createMuiTheme } from '@material-ui/core/styles';

const MuseoLight = {
  fontFamily: 'museo_sans_500regular',
  fontStyle: 'normal',
  // fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
    local('museo_sans_500regular'),
    url(${MuseoLightFont}) format('woff')
  `,
  // unicodeRange:
  //   'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const VAGBold = {
  fontFamily: '"VAG Rounded LT W04 Bold"',
  // fontStyle: 'normal',
  // // fontDisplay: 'swap',
  // fontWeight: 'normal',
  src: `
    url(${VAGBoldFont}) format('woff2')
  `,
  // unicodeRange:
  //   'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#0063b3',
      },
      secondary: {
        main: '#ffcb05'
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [MuseoLight,VAGBold],
        },
      },
    },
    typography: {//"Roboto", "Helvetica", "Arial", sans-serif
      fontFamily: 'museo_sans_500regular !important'
    }
  });