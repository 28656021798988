import React from 'react';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import IconButton from '@material-ui/core/IconButton';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter2Icon from '@material-ui/icons/Filter2';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import DownloadIcon from '@material-ui/icons/CloudDownload'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import ShareIcon from '@material-ui/icons/Share';
import SettingsIcon from '@material-ui/icons/Settings';
import Toolbar from '@material-ui/core/Toolbar';

import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
const exporter = new GLTFExporter();



export function RibbonToolbar({ editor , openSettingsModal, saveDesign, openShareModal,  ...other }) {
  const [transformOn, setTransformOn] = React.useState(true);
  const [rotateOn, setRotateOn] = React.useState(false);
  const [twoDOn, setTwoDOn] = React.useState(true);



  function exportDesign() {
    exporter.parse(editor.models, gltf => {
      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link)
      console.log(gltf)

      function save(blob, filename) {
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }

      function saveString(text, filename) {
        save(new Blob([text], { type: 'text/plain' }), filename);
      }

      saveString(JSON.stringify(gltf), 'design.gltf')
    });
  }


  function screenshot() {
    var download = document.createElement('a')
    download.setAttribute('download', 'screenshot.png')
    var image = document.getElementById("3dcanvas").children[0].toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    download.setAttribute("href", image);
    download.click()
  }



  function handleEditorToolClick(toolId) {
    if (toolId === 3)
        editor.tools.toggle2D3D();
    else
        editor.tools.select(toolId);
  }

  const toggleTransform = () => {
    handleEditorToolClick(1);
    if (transformOn)
     {
       setTransformOn(false);
     }else {
       setTransformOn(true);
       setRotateOn(false);
     }
  }

  const toggleRotate = () => {
    handleEditorToolClick(2);
    if (rotateOn)
     {
      setRotateOn(false);
     }else {
       setTransformOn(false);
       setRotateOn(true);
     }
  }

  const toggleTwoD = () => {
    handleEditorToolClick(3);
    setTwoDOn(!twoDOn);
  }
  
  return (
    <Toolbar {...other}>
      <IconButton color="inherit" onClick={toggleTransform} selected={transformOn} title="Move">
        <OpenWithIcon />
      </IconButton>
      <IconButton color="inherit" onClick={toggleRotate} selected={rotateOn} title="Rotate">
        <CropRotateIcon />
      </IconButton>
      <IconButton color="inherit" onClick={toggleTwoD} title="2D/3D toggle">
        {twoDOn ? <Filter2Icon /> : <Filter3Icon />}
      </IconButton>
      <Divider orientation="vertical" flexItem style={{ backgroundColor: 'white', marginTop: '10px', marginBottom: '10px' }} />
      <IconButton color="inherit" onClick={saveDesign} title="Save design" >
        <SaveIcon />
      </IconButton>
      <IconButton color="inherit" onClick={exportDesign} title="Download design" >
        <DownloadIcon />
      </IconButton>
      <IconButton color="inherit" onClick={screenshot} title="Download screenshot">
        <PhotoCameraIcon />
      </IconButton>
      <IconButton color="inherit" onClick={openShareModal} title="Share design">
        <ShareIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem style={{ backgroundColor: 'white', marginTop: '10px', marginBottom: '10px' }} />
      <IconButton color="inherit" onClick={openSettingsModal} title="Scene settings">
        <SettingsIcon />
      </IconButton>
    </Toolbar>
  );
}

