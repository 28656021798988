import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { ModelLoader } from '../../utils';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 100,
    margin: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.primary.main,
    whiteSpace: 'break-spaces',
    padding: '0 1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'center'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));



export default function ModelMediaCard({editor, product, addModelToDesign  }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const addModel = (id, objectKey) => async () => {
    try {
      setLoading(true);
      const gltf = await ModelLoader(objectKey, setProgress);
      setLoading(false);
      addModelToDesign(product, gltf);
    } catch (err) {
      setLoading(false);
      alert(err);
      console.log(err);

    }


  }

  return (
    <Card className={classes.root}>
      
        
        <CardMedia
          component="img"
          alt={product.name}
          height="100"
          image={product.thumbnail_url || 'https://dummyimage.com/200x200/fff/aaa'}
          title={product.name}
        />

        <CardActionArea>
        <a href={product.prod_page_url || undefined} target="_blank" rel="noopener noreferrer" title={product.name} style={{textDecoration:'none'}}>
         <Typography className={classes.heading}>
          {product.name}
          </Typography>
          </a>
          <Typography className={classes.subheading}>
          {product.code}
          </Typography>

      </CardActionArea>
      <CardActions>
      <div className={classes.wrapper}>
        <Fab
          aria-label="add"
          color="secondary"
          onClick={addModel(product.code,product.gltf_object_key)}
          disabled={loading}
        >
          <AddIcon />
        </Fab>
        {loading && <CircularProgress size={68} className={classes.fabProgress} variant="static" value={progress} />}
      </div>
        {/* <Button size="small" color="primary">
          Learn More
        </Button> */}
      </CardActions>
    </Card>
  );
}
