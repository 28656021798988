import * as THREE from 'three'
import * as BOUND from './Boundaries'

import { init as physics } from './Physics';

import tools from './Tools';

import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';

class Editor {
    constructor() {

        this.init();

    }

    async init() {
        //set up basic THREE scene etc
        this.scene = new THREE.Scene();
        this.overlay = new THREE.Scene();
        this.refCounter=0;

        THREE.Cache.enabled = true;

        this.scene.background = new THREE.Color(0xdddddd);

        this.renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFShadowMap

        this.designUpdateCallback = null;


        //cameras
        this.cameras = {}

        const fov = 30;
        const aspect = 2
        const near = 0.01
        const far = 2000
        this.cameras.orbit = new THREE.PerspectiveCamera(fov, aspect, near, far)

        this.cameras.tudi = new THREE.OrthographicCamera(-32, 32, 32, -32)
        this.cameras.tudi.position.set(0, 30, 0)
        this.cameras.tudi.rotation.set(-Math.PI/2, 0, 0)
        this.cameras.selected = this.cameras.tudi

        //lighting
        const sun_dist = 100
        const sun = new THREE.DirectionalLight(0xffffff)
        sun.position.set(0.8132696589343831*sun_dist, 0.38281106372946816*sun_dist, 0.43823298751128426*sun_dist)

        sun.castShadow = true
        sun.shadow.mapSize.width = 8192
        sun.shadow.mapSize.height = 8192
        sun.shadow.bias = -0.001
        sun.intensity = 1.5

        sun.shadow.camera.left = 100
        sun.shadow.camera.right = -100
        sun.shadow.camera.top = 100
        sun.shadow.camera.bottom = -10

        const lights = new THREE.Group()
        lights.add(sun)
        const light = new THREE.HemisphereLight(0xffeeb1, 0x080820)
        light.intensity = 1.5
        lights.add(light)
        this.scene.add(lights)

        //scenery
        const scenery = new THREE.Group()
        this.scenery = scenery
        const ground_geom = new THREE.PlaneGeometry(1000, 1000)
        const ground_text = new THREE.TextureLoader().load('/grass.jpg')
        ground_text.wrapS = THREE.RepeatWrapping
        ground_text.wrapT = THREE.RepeatWrapping
        ground_text.repeat.set(1600, 1600)
        const ground_mat = new THREE.MeshBasicMaterial({map: ground_text})
        
        ground_mat.depthWrite = false

        const ground = new THREE.Mesh(ground_geom, ground_mat)
        ground.renderOrder = 50

        ground.rotation.set(-Math.PI/2, 0, 0)
        ground.position.set(0, -0.01, 0)
        scenery.add(ground)
        this.scene.add(scenery)


        //environment
        this.environment = {
            mounted: false,
            selected: null,
            design: {
                layout: {},
                components: [

                ]
            }
        };

        this.measurements = {
            area :0.0,
            perimeter: 0.0
        };

        this.models = new THREE.Group()
        this.scene.add(this.models)

        /* const cube_mesh = new THREE.BoxGeometry(1,1,1)
        const cube_mat = new THREE.MeshBasicMaterial({color: '#ff0000'})
        const cube = new THREE.Mesh(cube_mesh, cube_mat)
        this.models.add(cube) */

        //tools
        this.tools = tools(this, this.updateBoundaries.bind(this));

        this.physics = await physics();
    }

    animate() {
        console.log('animate running ...');
        this.cameras.selected = this.cameras.orbit;
        this.cameras.selected.position.set(0, 0, 10);
        this.tools.toggle2D3D(); // default to 2D  --- NATE to fix 

        const scope = this;

        function anim() {
            scope.renderer.setSize(scope.host.clientWidth, scope.host.clientHeight)
            scope.cameras.selected.aspect = scope.host.clientWidth / scope.host.clientHeight
            scope.cameras.selected.updateProjectionMatrix()
            if (scope.environment.mounted) {
                //requestAnimationFrame(anim);
                scope.renderer.setAnimationLoop(anim);
                scope.renderer.autoClear = false
                scope.renderer.render(scope.scene, scope.cameras.selected)
                scope.renderer.render(scope.overlay, scope.cameras.selected)
                scope.renderer.autoClear = true
            }
        }

        anim();
    }

    handleWidthHeightUpdate() {
        const newWidth = this.host.clientWidth;
        const newHeight= this.host.clientHeight;
        //console.log(newWidth+' , '+newHeight);
        this.renderer.setSize(newWidth, newHeight);
        this.cameras.orbit.aspect = newWidth / newHeight;
        this.cameras.orbit.updateProjectionMatrix();
        const tudi = this.cameras.tudi;
        tudi.left = -newWidth/50;
        tudi.right = newWidth/50;
        tudi.bottom = -newHeight/50;
        tudi.top = newHeight/50;
    }

    handleSetSceneSetting ({fov}) {
        this.cameras.orbit.fov=fov;
    }

    updateColours() {

        /*for (var model of this.models.children) {
			model.collisionMesh.material.color.set(0x005500)
		}
		this.physics.updateCol(this.models.children)
		const colliders = this.physics.getCol()
		if (colliders.length !== 0) {
			for (var mesh of colliders) {
				mesh.material.color.set(0x550000)
			}
		}*/
    }

    initialiseBoundaries(layout) {
        const zone_mat = new THREE.MeshStandardMaterial({ color: 0x005500, opacity: 0.3, transparent: true });
        let zone,zone_geom;
        switch (layout.type) {
            case 'box':
                zone_geom = new THREE.PlaneGeometry(layout.dims.width, layout.dims.depth)
                zone = new THREE.Mesh(zone_geom, zone_mat)
                zone.rotation.x = Math.PI / -2
                zone.position.y = 0.02
                this.overlay.add(zone);
                this.measurements = {
                    area: layout.dims.width * layout.dims.depth,
                    perimeter: 2 * (layout.dims.width + layout.dims.depth)
                };
                break;
            case 'circle':
                zone_geom = new THREE.CircleGeometry(layout.dims.radius, 100)
                zone = new THREE.Mesh(zone_geom, zone_mat)
                zone.rotation.x = Math.PI / -2
                zone.position.y = 0.02
                this.overlay.add(zone);
                this.measurements = {
                    area: Math.PI * layout.dims.radius * layout.dims.radius,
                    perimeter: 2 * Math.PI * layout.dims.radius
                };
                break
            case 'auto':
                zone = BOUND.getBoundaryObject(this.models, layout)
                if (zone) {
                    zone.position.y = 0.02
                    this.overlay.add(zone);
                    this.measurements = {
                        area: BOUND.getArea(zone.geometry.vertices),
                        perimeter: BOUND.getPerimeter(zone.geometry.vertices),
                    };
                }
                break
            default:
                alert('error loading boundaries');
        }
        if (typeof (this.designUpdateCallback) === 'function')
          this.designUpdateCallback(this.measurements);
        this.zone = zone;
    }

    updateBoundaries() {
        if (this.environment.design.layout.type === 'auto') {
            this.clearBoundaries();
            this.initialiseBoundaries(this.environment.design.layout);
        }
    }

    clearBoundaries() {
        this.overlay.remove(this.zone)
    }


    /* Function below to be ported into react side. 

    getCosting() {
        //costing information is used as a placeholder while awaiting data from moduplay
        const costing = {
            area_organic: 60,
            area_synthetic: 250,
            perimeter: 130,
            equipment: 1000
        }
        const sceneData = this.environment

        const models = sceneData.design.components
        const model_cost = models.length * costing.equipment
        

        const zone = this.zone
        const zone_area = BOUND.getArea(zone.geometry.vertices)
        const zone_cost = zone_area * costing.area_synthetic

        const perimeter_cost = 0

        const grandTotal = model_cost + zone_cost + perimeter_cost

        return {
            groundAreaCost: zone_cost,
            groundPerimeterCost: perimeter_cost,
            equipmentSubTotal: model_cost,
            grandTotal: grandTotal
        }
    }
    */

    processAndAddModel(modelData, modelGltf,doUpdateLayout) {
        try {
            modelData.ref=this.refCounter;
            this.refCounter++;

            let model;

            if (modelGltf.scene.getObjectByName("fallzones") === undefined) {
                model = modelGltf.scene
            } else {
                model = modelGltf.scene.getObjectByName("fallzones").parent
            }

            model.traverse((child) => {
                if (child.isMesh) {
                    child.castShadow = true
                    child.receiveShadow = true
                }
            });
            if ("position" in modelData) {
                model.position.set(modelData.position.x, modelData.position.y, modelData.position.z);
            }
            if ("rotation" in modelData) {
                model.rotation.set(modelData.rotation.x, modelData.rotation.y, modelData.rotation.z);
            }
            
            
            model.ref = modelData.ref;

            this.physics.couple(model)
             
            model.updateMatrixWorld()
            this.physics.updateCollisionMeshes(model)
            const collisions = this.physics.filterCollisions(this.physics.getCollisions())

            this.models.add(model);
            this.environment.design.components.push(modelData);

            this.tools.collision.resetMeshDisplay() 
            this.tools.collision.updateMeshDisplay(collisions)

            if (doUpdateLayout)
            {
                this.updateBoundaries();
            }


        } catch (err) {
            console.log('Error: ', err);
        }
    }

    removeModelFromDesign(index) {// should remove a model from current design
        //NOTE index is the array index of the element to be removed
        const component = this.environment.design.components[index];
        const model = this.models.children.find(model => model.ref === component.ref)
        this.models.remove(model)
        this.physics.removeModel(model)
        this.environment.design.components.splice(index,1);
        this.updateBoundaries();
    }

    clearDesign() { // clears current design ( remove all models)
        //
        while (this.models.children.length > 0) {
            this.models.remove(this.models.children[0])
        }
        this.clearBoundaries()
        this.refCounter=0;
        this.environment.design = {
            layout: {},
            components: [

            ]
        };

    }

    createNewDesign(layout) {
        this.clearDesign();
        this.environment.design = {
            layout: layout,
            components: [

            ]
        };
        this.initialiseBoundaries(layout);
        return  this.environment.design;


    }

    loadDesign(designObject, gltfs) { // given a design object should call clearDesign and load the given designObject into the scene.
        try {
            this.clearDesign();
            this.environment.design.layout = designObject.scenedata.layout;
            designObject.scenedata.components.forEach((model, i) => {
                this.processAndAddModel(model, gltfs[i], false); //boundaries will be updated all at once below

            });

            this.initialiseBoundaries(this.environment.design.layout);



            //setTimeout(() => this.updateColours(), 1/16) //change this to make more elegant when possible
        } catch (err) {
            console.log(err);
        }
    }

    getCurrentDesign() { // return the current design to be saved 
        //
        return this.environment.design
    }

    mount(host) {
        this.host = host;
        this.environment.mounted = true;
        host.appendChild(this.renderer.domElement);
        this.animate();
        //VR
        const vrBtn=VRButton.createButton(this.renderer);
        vrBtn.style.position='relative';
        vrBtn.style.bottom='50px';
        document.getElementById('3dcanvas').appendChild(vrBtn);
        this.renderer.xr.enabled = true;
    }

    unmount() {
        try {
            this.clearDesign();
            this.host.removeChild(this.renderer.domElement);
            this.environment.mounted = false;
        } catch (err) {
            console.log(err);
        }
    }
}

export default Editor