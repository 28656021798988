import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {Storage} from 'aws-amplify';


const loader = new GLTFLoader();


const GLTFLoaderPromise = (url, progressCB) => new Promise((resolve, reject) => { 
    loader.load(url,
        gltf => {
            //console.log('success', gltf);
            resolve(gltf);

        }, (xhr) => {
            if ((typeof progressCB) === 'function' )
             progressCB((xhr.loaded / xhr.total * 100));

        }, (error) => {
            reject(error);
            console.log('GLTF loader err: ', error);
        }
    );
});

const getBase = (key) => (key.substr(0,key.lastIndexOf('/')+1));

const removeSubPath= (path) => (path.substr(path.lastIndexOf('/')+1))

export const ModelLoader = async (key, progressCB) => {
    try {
        const resp = await Storage.get(key, { download: true });
       
        const str = await resp.Body.text();

        const gltfScene = JSON.parse(str);
        console.log(gltfScene);

        if (gltfScene.hasOwnProperty('buffers')) {
            for (let i = 0; i < gltfScene.buffers.length; i++) {
                gltfScene.buffers[i].uri = await Storage.get(getBase(key)+removeSubPath(gltfScene.buffers[i].uri)); 
            }
        }

        if (gltfScene.hasOwnProperty('images')) {
            for (let i = 0; i < gltfScene.images.length; i++) {
                gltfScene.images[i].uri = await Storage.get(getBase(key)+removeSubPath(gltfScene.images[i].uri)); 
            }
        }
        
        const updatedSceneFileContent = JSON.stringify(gltfScene, null, 2);
        const updatedBlob = new Blob([updatedSceneFileContent], { type: 'text/plain' });
        const updatedUrl = window.URL.createObjectURL(updatedBlob);

        const resovedScene= await GLTFLoaderPromise(updatedUrl,progressCB);

        return resovedScene; //gltf tree
    }

    catch (err) {
        console.log(err);
    }

}