import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import {Collapse, List, ListItemSecondaryAction} from '@material-ui/core';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter2Icon from '@material-ui/icons/Filter2';
import Divider from '@material-ui/core/Divider';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  maxwidth: {
    overflow:'hidden', 
    textOverflow:'ellipsis'
  }
}));

// Create our number formatter.
const priceFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0,
  //maximumFractionDigits: 0,
});


export function MainListItems({ editor , openSettingsModal }) {
  const [transformOn, setTransformOn] = React.useState(true);
  const [rotateOn, setRotateOn] = React.useState(false);
  const [twoDOn, setTwoDOn] = React.useState(true);

  function handleEditorToolClick(toolId) {
    if (toolId === 3)
        editor.tools.toggle2D3D();
    else
        editor.tools.select(toolId);
  }

  const toggleTransform = () => {
    handleEditorToolClick(1);
    if (transformOn)
     {
       setTransformOn(false);
     }else {
       setTransformOn(true);
       setRotateOn(false);
     }
  }

  const toggleRotate = () => {
    handleEditorToolClick(2);
    if (rotateOn)
     {
      setRotateOn(false);
     }else {
       setTransformOn(false);
       setRotateOn(true);
     }
  }

  const toggleTwoD = () => {
    handleEditorToolClick(3);
    setTwoDOn(!twoDOn);
  }
  
  return (
    <div>
      {/* <ListItem button onClick={openLayoutModal}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Layout" />
      </ListItem> */}
      <ListItem button onClick={toggleTransform} selected={transformOn}>
        <ListItemIcon>
          <OpenWithIcon />
        </ListItemIcon>
        <ListItemText primary="Move" />
      </ListItem>
      <ListItem button onClick={toggleRotate} selected={rotateOn}>
        <ListItemIcon>
          <CropRotateIcon />
        </ListItemIcon>
        <ListItemText primary="Rotate" />
      </ListItem>
      <ListItem button onClick={toggleTwoD} >
        <ListItemIcon>
          { twoDOn ? <Filter2Icon /> : <Filter3Icon />  }
        </ListItemIcon>
        <ListItemText primary="2D/3D View" />
      </ListItem>
      <ListItem button onClick={openSettingsModal} >
        <ListItemIcon>
           <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Scene settings" />
      </ListItem>
    </div>
  );
}




export function SecondaryListItems({drawerOpen, models, onModelRemove, measurements, currentDesign , allMaterials, setLeftTabValue}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);



  const handleClick = () => {
    setOpen(!open);
  };

  const getSoftFallMaterial = ()  =>
  {
    const material=allMaterials.find((m)=>(m.id === currentDesign.metadata?.softFallId));
    return material || {};
  }

  const getEdgeMaterial  = ()  =>
  {
    // console.log(currentDesign.metadata);
    // console.log(currentDesign.metadata?.edgeId);
    const material=allMaterials.find((m)=>(m.id === currentDesign.metadata?.edgeId));
    return material || {};
  }

  const getTotalPrice = () => {
   const modelsTotal= models.reduce((acc,cur)=>(acc+cur.price),0);
   return modelsTotal + (getSoftFallMaterial().price || 0) *(measurements.area.toFixed(2)) + (getEdgeMaterial().price || 0) *(measurements.perimeter.toFixed(2));
  }


  return ((currentDesign && allMaterials) && <div>
    <ListItem button onClick={handleClick}>
        <ListItemIcon>
        <AccountTreeIcon />
        </ListItemIcon>
        <ListItemText primary="Cost Estimate" secondary={priceFormatter.format(getTotalPrice())} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />  
      <List component="div" disablePadding>
      <ListItem button className={classes.nested}>
          <ListItemIcon >
            <img src="Cost_Symbol.svg" style={{width: '26px'}}/>
          </ListItemIcon>
          <ListItemText title={getSoftFallMaterial().title || 'None'} className={classes.maxwidth} primary={<Typography variant='caption' >{getSoftFallMaterial().title || 'None'}</Typography>} primaryTypographyProps={{variant:'caption'}} secondary={<span>{(measurements.area).toFixed(2)+' '}<b>m<sup>2</sup></b>{`x $${getSoftFallMaterial().price ||0}`}</span> } />
          <ListItemSecondaryAction>
              {drawerOpen && <IconButton edge="end" aria-label="delete" onClick={() => setLeftTabValue(2)} >
              <EditIcon />
            </IconButton>}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem button className={classes.nested}>
          <ListItemIcon>
          <img src="Cost_Symbol.svg" style={{width: '26px'}}/>
          </ListItemIcon>
          <ListItemText title={getEdgeMaterial().title || 'None'} className={classes.maxwidth} primary={<Typography variant='caption' >{getEdgeMaterial().title || 'None'} </Typography>}  secondary={<span>{(measurements.perimeter).toFixed(2)+' '}<b>m</b>{`x $${getEdgeMaterial().price || 0}`}</span> } />
          <ListItemSecondaryAction>
              {drawerOpen && <IconButton edge="end" aria-label="delete" onClick={() => setLeftTabValue(2)} >
              <EditIcon />
            </IconButton>}
          </ListItemSecondaryAction>
        </ListItem>
        {models && models.map((model,ind) => (
        <ListItem button className={classes.nested} key={ind}>
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <ListItemText title={model.name} className={classes.maxwidth} primary={<Typography variant='caption' >{model.code+' - '+model.name}</Typography>} secondary={priceFormatter.format(model.price)} />
          <ListItemSecondaryAction>
              {drawerOpen && <IconButton edge="end" aria-label="delete" onClick={() => { onModelRemove(ind); }} >
              <DeleteIcon />
            </IconButton>}
          </ListItemSecondaryAction>
        </ListItem>))
}
      </List>
      <Divider />      
      {/* <ListItem button className={classes.nested} >
          {/* <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon> 
          <ListItemText className={classes.maxwidth} primary={'Sub total'} primaryTypographyProps={{variant:'caption'}}  />
          <ListItemSecondaryAction>
           { drawerOpen && <span>{priceFormatter.format(models.reduce((acc,cur)=>(acc+cur.price),0))}</span> }
          </ListItemSecondaryAction>
        </ListItem> */}
      <ListItem button className={classes.nested} >
          {/* <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon> */}
          <ListItemText className={classes.maxwidth} primary={'Total Area (est.)'} primaryTypographyProps={{variant:'caption'}}  />
          <ListItemSecondaryAction>
           { drawerOpen && <span>{(measurements.area).toFixed(2)+' '}<b>m<sup>2</sup></b></span> }
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem button className={classes.nested} >
          {/* <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon> */}
          <ListItemText className={classes.maxwidth} primary={'Total Perimeter (est.)'} primaryTypographyProps={{variant:'caption'}}  />
          <ListItemSecondaryAction>
           { drawerOpen && <span>{(measurements.perimeter).toFixed(2)+' '}<b>m</b></span> }
          </ListItemSecondaryAction>
        </ListItem>
    </Collapse>
    

  </div>
  );
}

