import React from 'react';
import { makeStyles ,ThemeProvider ,withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {theme} from '../Style/Theme';
import {API,Storage} from 'aws-amplify';
import Header from '../Components/Menu/Header';
// import "@google/model-viewer/dist/model-viewer";

import Box from '@material-ui/core/Box';








const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  modelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '1rem'
  }
}));

function ModelCard({product})
{
  const [gltfURL,setGltfURL]=React.useState('');
  const [usdzURL,setUsdzURL]=React.useState('');
  const [ready,setReady]=React.useState(false);

  React.useEffect(() => {
    const loadData = async () => {
      const gltfurl=await Storage.get(product.gltf_object_key);
      const usdz=product.usdz_object_key ? (await Storage.get(product.usdz_object_key)) : '';
      console.log(gltfurl);
      setGltfURL('');
      setUsdzURL(usdz);
      setReady(true);

    };
    loadData();
}, []);

  return (
    ready && usdzURL &&
     <a href={usdzURL} rel='ar'>
       <img src={product.thumbnail_url || 'https://dummyimage.com/200x200/fff/aaa'} style={{marginRight: '1rem'}}/>
     </a>
      //  <model-viewer
      //     src={gltfURL}
      //     ios-src={usdzURL}
      //     poster={product.thumbnail_url || 'https://dummyimage.com/200x200/fff/aaa'}
      //     alt="A 3D model "
      //     ar
      //   ></model-viewer>
  );

}


/* code, name, category_id, gltf_object_key, usdz_object_key, bin_img_object_keys, prod_page_url, thumbnail_url, price */
export default function AR() {
  const classes = useStyles();
  const [products,setProducts] = React.useState([]);




    React.useEffect(() => {
        const loadData = async () => {
          const allprods= await API.get('ModuplayAPI', '/client/products', {
            queryStringParameters: {  // OPTIONAL
                q: '',
            },
        });
        setProducts(allprods);
        };
        loadData();
    }, []);




  


  return (
    <ThemeProvider theme={theme}>
              <CssBaseline />
        <Header />
        <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Box  className={classes.modelsContainer}>
        {products.map((prod) => (<ModelCard key={prod.code} product={prod} />))}
        </Box>
      </main>

    </ThemeProvider>
  );
}