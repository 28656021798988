import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';


const getAPIEndPoint = () => {
  return ((process.env.NODE_ENV === 'production') || (process.env.REACT_APP_PROD_DB === 'true')) ? 'https://byf09dqb55.execute-api.ap-southeast-2.amazonaws.com/dev' :  'http://localhost:8080/local';
}

Amplify.configure({
  Auth: {
    identityPoolId: "ap-southeast-2:65092d35-4466-4706-a7e5-528cd832468f",
    mandatorySignIn: true,
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_uInIGFqtX",
    userPoolWebClientId: "7i50h7du4e2rcqnhlu12k93hlk",
    
  },
	API: {
		endpoints: [
			{
				name: "ModuplayAPI",
				endpoint: getAPIEndPoint(),
				region: "ap-southeast-2"
			}
    ]
  },
  Storage: {
    AWSS3: {
        bucket: 'moduplay-models', //REQUIRED -  Amazon S3 bucket name
        region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
