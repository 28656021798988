import Ammo from 'ammo.js/builds/ammo'
import * as THREE from 'three'

const collision_margin = 0.01

async function init() {
    const AMMO = await Ammo()

    const   collisionConfiguration  = new AMMO.btDefaultCollisionConfiguration(),
            dispatcher              = new AMMO.btCollisionDispatcher(collisionConfiguration),
            overlappingPairCache    = new AMMO.btDbvtBroadphase(),
            solver                  = new AMMO.btSequentialImpulseConstraintSolver()

    const world = new AMMO.btDiscreteDynamicsWorld(dispatcher, overlappingPairCache, solver, collisionConfiguration)
    const clock = new THREE.Clock()

    function couple(model){
        model.userData.collisionMeshes = []
        const ez = model.getObjectByName("EZ")
        const sfz = model.getObjectByName("SFZ")
        const fmz = model.getObjectByName("FMZ")
        const fmzr1 = model.getObjectByName("FMZ_R1")
        const fmzr2 = model.getObjectByName("FMZ_R2")

        const collisionObjects = [ez, sfz, fmz, fmzr1, fmzr2].filter(obj => obj !== undefined)
        //const fallzones = model.getObjectByName("fallzones")
        //const display = model.getObjectByName("model")

        for (const collisionObject of collisionObjects) {
            const parentCollisionShape = getAmmoComp(collisionObject)

            const parentPosition = new THREE.Vector3()
            const parentQuaternion = new THREE.Quaternion()

            collisionObject.getWorldPosition(parentPosition)
            collisionObject.getWorldQuaternion(parentQuaternion)

            const parentTransform = new AMMO.btTransform()
            parentTransform.setIdentity()
            parentTransform.setOrigin(new AMMO.btVector3(parentPosition.x, parentPosition.y, parentPosition.z))
            parentTransform.setRotation(new AMMO.btQuaternion(parentQuaternion.x, parentQuaternion.y, parentQuaternion.z, parentQuaternion.w))

            const motionState = new AMMO.btDefaultMotionState(parentTransform)
            const localInertia = new AMMO.btVector3(0, 0, 0)
            parentCollisionShape.calculateLocalInertia(0, localInertia)

            const rigidBodyInfo = new AMMO.btRigidBodyConstructionInfo(0, motionState, parentCollisionShape, localInertia)
            const rigidBody = new AMMO.btRigidBody(rigidBodyInfo)

            rigidBody.setActivationState(4)
            rigidBody.setCollisionFlags(2)

            world.addRigidBody(rigidBody, 1, 1)
            rigidBody.threeObject = collisionObject
            rigidBody.threeParent = model
            collisionObject.userData.physicsBody = rigidBody
            collisionObject.userData.parent = model
            model.userData.collisionMeshes.push(collisionObject)
        }
        
    }

    function getAmmoComp(object) {
        const colliders = []
        for (var child of object.children) {
            const bufferGeometry = child.geometry
            const geometry = new THREE.Geometry().fromBufferGeometry(bufferGeometry)

            const tempPosition = child.position//new THREE.Vector3()
            const tempQuaternion = child.quaternion//new THREE.Quaternion()

            //child.getWorldPosition(tempPosition)
            //child.getWorldQuaternion(tempQuaternion)

            const transform = new AMMO.btTransform()
            transform.setIdentity()
            transform.setOrigin(new AMMO.btVector3(tempPosition.x, tempPosition.y, tempPosition.z))
            transform.setRotation(new AMMO.btQuaternion(tempQuaternion.x, tempQuaternion.y, tempQuaternion.z, tempQuaternion.w))
            
            const collisionShape = getAmmoGeom(geometry)
            collisionShape.setMargin(collision_margin)

            colliders.push({trans: transform, col: collisionShape})
        }

        const parentCollisionShape = new AMMO.btCompoundShape()
        for (var collider of colliders) {
            parentCollisionShape.addChildShape(collider.trans, collider.col)
        }

        return parentCollisionShape
    }

    function getAmmoGeom(geometry) {
        const vertices = geometry.vertices
    
        const shape = new AMMO.btConvexHullShape()
    
        for (var vertex of vertices) {
            //console.log(vertex)
            shape.addPoint(new AMMO.btVector3(vertex.x, vertex.y, vertex.z))
        }
        
        return shape
    }

    function updateCollisionMeshes(model) {
        const deltaTime = clock.getDelta()

        const __thPosition = new THREE.Vector3()
        const __thQuaternion = new THREE.Quaternion()
        const __btPosition = new AMMO.btVector3()
        const __btQuaternion = new AMMO.btQuaternion()
        const __transform = new AMMO.btTransform()

        for (const collider of model.userData.collisionMeshes) {
            collider.getWorldPosition(__thPosition)
            collider.getWorldQuaternion(__thQuaternion)

            const physicsBody = collider.userData.physicsBody
            const motionState = physicsBody.getMotionState()

            if (motionState) {
                __btPosition.setValue(__thPosition.x, __thPosition.y, __thPosition.z)
                __btQuaternion.setValue(__thQuaternion.x, __thQuaternion.y, __thQuaternion.z, __thQuaternion.w)

                __transform.setIdentity()
                __transform.setOrigin(__btPosition)
                __transform.setRotation(__btQuaternion)

                motionState.setWorldTransform(__transform)
            }


        }

        world.stepSimulation(deltaTime, 10)
    }

    function getCollisions() {
        const dispatcher = world.getDispatcher()
        const numManifolds = dispatcher.getNumManifolds()
        const colliders = []
        var collided

        for (var i = 0; i < numManifolds; i++) {
            const contactManifold = dispatcher.getManifoldByIndexInternal(i)
            const rb0 = AMMO.castObject(contactManifold.getBody0(), AMMO.btRigidBody)
            const rb1 = AMMO.castObject(contactManifold.getBody1(), AMMO.btRigidBody)

            collided = false

            const numContacts = contactManifold.getNumContacts()
            for (var j = 0; j < numContacts; j++) {
                const contactPoint = contactManifold.getContactPoint(j)
                const distance = contactPoint.getDistance()

                if (distance <= 0) {
                    collided = true
                    break
                }
            }
            if (collided) {
                colliders.push([rb0.threeObject, rb1.threeObject])
            }
        }

        return colliders
    }

    function filterCollisions(collisions) {
        const filtered = []
        for (const collider of collisions) {
            let unique = true
            for (const filt of filtered) {
                if (filt[0] === collider[0] && filt[1] === collider[1]) {
                    unique = false
                    break
                }
            }
            if (unique === true) {
                filtered.push(collider)
            }
        }

        const filtered2 = []
        for (const filt of filtered) {
            if (filt[0].userData.parent !== filt[1].userData.parent) {
                filtered2.push(filt)
            }
        }
        return filtered2
    }

    function removeModel(model) {
        for (const mesh of model.userData.collisionMeshes) {
            world.removeRigidBody(mesh.userData.physicsBody)
        }
    }


    const physics = {
        couple,
        updateCollisionMeshes,
        getCollisions,
        filterCollisions,
        removeModel
    }
    return physics
}



export { init }