import * as THREE from 'three'
import * as hull from 'hull.js'

function asPoints(object) {
    let globalPosition = new THREE.Vector3()
    const quaternion = new THREE.Quaternion();
    object.getWorldPosition(globalPosition);
    object.getWorldQuaternion(quaternion);
    let geom = new THREE.Geometry().fromBufferGeometry(object.geometry);
    let points = geom.vertices.map(vertex => vertex.applyQuaternion(quaternion).add(globalPosition));
    points = points.map(vertex => [vertex.x, vertex.z])
    return points
}

function getLineObject(vertices) {
    const lineGeom = new THREE.Geometry()
    lineGeom.vertices.push(...vertices)
    //lineGeom.vertices.push(vertices[0])
    const lineMat = new THREE.LineBasicMaterial({color: 0x000000, linewidth: 2})
    //const lineMat = new THREE.MeshStandardMaterial({ color: 0x005500, opacity: 0.3, transparent: true })
    const lineObject = new THREE.Line(lineGeom, lineMat)
    return lineObject
}

function getFallzones(objects) {
    let fallzones = [];
    try {
        for (let obj of objects.children) {
            if (obj.getObjectByName("fallzones")) {
                obj.getObjectByName("fallzones").traverse(
                    fzChild => {
                        if (fzChild.type === "Mesh") {
                            fallzones.push(fzChild)
                        }
                    }
                );
            }
        }
    } catch (err) {
        console.warn(err);
    }
    return fallzones
}

function getBoundaryObject(objects, params) {
    const config = {
        concavity: (params?.concavity)|| Infinity, 
        scale: (params?.scale) || 1.0
    };
    let fallzones = getFallzones(objects)
    if (fallzones.length === 0)
       return null;
    let points = fallzones.map(zone => asPoints(zone))
    points = [].concat(...points)
    let pointsHull = hull(points, config.concavity).map(point => {return {x: point[0], z: point[1]}})
    //let hull = grahamScan(points).map(point => {return {x: point[0], z: point[1]}})
    let lineObject = getLineObject(pointsHull.map(vertex => new THREE.Vector3(vertex.x , 0, vertex.z )))
    return lineObject
}

// Function below has been borrowed from: https://github.com/mrdoob/three.js/blob/master/src/extras/ShapeUtils.js 
function getArea( contour ) {
    const n = contour.length;
    let a = 0.0;

    for ( let p = n - 1, q = 0; q < n; p = q ++ ) {

        a += contour[ p ].x * contour[ q ].z - contour[ q ].x * contour[ p ].z;

    }

    return a * 0.5;
}

function getPerimeter( contour ) {
    let perim = 0;
    const n = contour.length;
    let last = contour[n-1];
    for (const vertex of contour) {
        perim += vertex.distanceTo(last)
        last = vertex
    }

    return perim;
}

export { asPoints, getBoundaryObject, getFallzones, hull, getLineObject, getArea, getPerimeter }