import React from 'react';
import { makeStyles ,ThemeProvider ,withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from '../Components/Menu/Header';
import {theme} from '../Style/Theme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import {API,Storage} from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';




function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function UploadFileFormControl({prodCode, label, id, keyPrefix, objectKey, setObjectKey, accept, multiple})
{
  const classes = useStyles();
  const [uploading,setUploading] = React.useState(false);
  const [uploaded,setUploaded] = React.useState(objectKey ? (objectKey.length>0) : false );
  const [uploadingProgress,setUploadingProgress] =  React.useState(0);

  const handleFileUpload = async (e) => {
    if (!prodCode)
    {
      window.alert('Please enter a product code first.');
      e.target.value = null;
      return;
    }

    console.log(e.target.files);
    try {
      
      setUploading(true);
      setUploadingProgress(0);
      const nFiles=e.target.files.length;
      let ind=0;
      for (const file of e.target.files) {
        const resp = await Storage.put(`${prodCode}/${keyPrefix}/${file.name}`, file, {
          progressCallback: (progress) => {
            const thisFileProgress=(progress.loaded / progress.total * 100.0);
            setUploadingProgress((ind*100+thisFileProgress)/nFiles);
            //console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          }
        });
        ind++;
        console.log(resp);
        setObjectKey((k) => {
          if (Array.isArray(k))
            return k.concat(resp.key);
          else
            return resp.key;
        });
      }

      setUploaded(true);
      
      
    } catch (error) {
      console.log("Error uploading file: ", error);
      window.alert('An error occured while uploading.');
    } finally {
      setUploading(false);
    }
  }

  const handleDelete = async () => {
    try {
      
      setUploading(true);
      
      const keys = (Array.isArray(objectKey)) ? objectKey : [objectKey];
      setUploadingProgress(0);
      for (const k of keys) {
        const resp = await Storage.remove(k);
        console.log(resp);
      }
     setObjectKey(multiple ? [] : '');
     setUploadingProgress(100);
     document.getElementById(id).value=null;



      setUploaded(false);
      
      
    } catch (error) {
      console.log("Error removing file: ", error);
      window.alert('An error occured while removing the files.');
    } finally {
      setUploading(false);
    }
  }

  return (
    <FormControl variant="outlined" className={classes.marginbottom} style={{ flexDirection: 'row', alignItems: 'center' }} fullWidth>
      <TextField
        disabled
        label={label}
        variant="outlined"
        value={objectKey}
        onChange={(ev) => { setObjectKey(ev.target.value) }}
        style={{ marginRight: '1rem', flexGrow: 1 }}
      />
      <input
        accept={accept}
        className={classes.input}
        id={id}
        type="file"
        onChange={handleFileUpload}
        multiple={multiple}
      />
      {uploading ? <CircularProgressWithLabel value={uploadingProgress} /> : (uploaded ?
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Remove
        </Button> :
        (<label htmlFor={id}>
          <Button variant="contained" color="primary" component="span">
            Upload
          </Button>
        </label>))
      }
    </FormControl>
  );
}


const drawerWidth = 300;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  ribbonToolbar: {
    backgroundColor: '#2A2E33',
    color: 'white'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  marginBtn: {
    marginRight: '1rem',
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontFamily: '"VAG Rounded LT W04 Bold" !important',
    textTransform: 'uppercase'
  },
  drawerPaper: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  modelDrawerPaper: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: drawerWidth*2,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9)
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight:  theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  paper: {
    padding: '5px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'CALC(100vh - 250px)',
  },
  modelTabsContainer: {
    padding: theme.spacing(1),
    display: 'flex'
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Roboto',
    textTransform:'uppercase'
  },
  modelsWrapper: {
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'flex-start' /* NEW */
  },
  logoImg: {
    maxHeight: '60px',
    marginRight: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  tabs: {
    height: 'CALC(100vh - 64px)'
  },
  tabVertFlexContainer: {
    flexDirection: 'column',
    height:'100%'
  },
  rotated: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap'
  },
  tabbtn : {
    flexGrow: 1,
    maxWidth: '3rem !important',
    minWidth: '1.8rem !important'
  },
  marginbottom: {
    marginBottom: theme.spacing(2)
  },
  input: {
    display: 'none'
  }
}));

const isAdmin = (user) => {
  const groups = user.signInUserSession.idToken.payload['cognito:groups'];
  return (Array.isArray(groups) && groups.indexOf("Admins") >=0);
}

/* code, name, category_id, gltf_object_key, usdz_object_key, bin_img_object_keys, prod_page_url, thumbnail_url, price */
export default function Admin({user}) {
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState('');
  const [dlopen, setDlOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [playmodes, setPlaymodes] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [newCode,setNewCode]=React.useState('');
  const [newName, setNewName]=React.useState('');
  const [playmodeID, setPlaymodeId]=React.useState(0);
  const [newCategoryID, setNewCategoryId]=React.useState(0);
  const [newGltfKey, setNewGltfKey]=React.useState('');
  const [newUsdzKey, setNewUsdzKey]=React.useState('');
  const [newBinImgKeys, setNewBinImgKeys]=React.useState([]);
  const [newProdPageUrl, setNewProdPageUrl]=React.useState('');
  const [newPrice, setNewPrice]=React.useState(0);
  const [newThumbnailUrl, setNewThumbnailUrl]=React.useState('');
  const [editInd,setEditInd]=React.useState(-1);



    React.useEffect(() => {
        const loadData = async () => {
          // console.log(user);
          const pms = await API.get('ModuplayAPI', '/client/playmodes');
          console.log(pms);
          setPlaymodes(pms);
          const cats=await API.get('ModuplayAPI', '/client/categories');
          console.log(cats);
          setCategories(cats);
          const allprods= await API.get('ModuplayAPI', '/client/products', {
            queryStringParameters: {  // OPTIONAL
                q: '',
            },
        });
        setProducts(allprods);
        };
        loadData();
    }, []);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackOpen(false);
    };


  const handleDlClose = () => {
    setDlOpen(false);
  };


  const handleAddNewProduct = () => {
     setNewCode('');
     setNewName('');
     setPlaymodeId(0);
     setNewCategoryId(0);
     setNewGltfKey('');
     setNewUsdzKey('');
     setNewBinImgKeys([]);
     setNewProdPageUrl('');
     setNewPrice(0);
     setNewThumbnailUrl('');
     setEditInd(-1);

     setDlOpen(true);

  }

  const handleEditProduct = React.useCallback((ind) => {
    setNewCode(products[ind].code);
    setNewName(products[ind].name);
    setPlaymodeId(categories.find((ct)=>(ct.id === products[ind].category_id)).playmode_id);
    setNewCategoryId(products[ind].category_id);
    setNewGltfKey(products[ind].gltf_object_key || '');
    setNewUsdzKey(products[ind].usdz_object_key || '');
    setNewBinImgKeys(products[ind].bin_img_object_keys || []);
    setNewProdPageUrl(products[ind].prod_page_url || '');
    setNewPrice(products[ind].price);
    setNewThumbnailUrl(products[ind].thumbnail_url);
    setEditInd(ind);

    setDlOpen(true);

 },[products,categories]);

  const handleDeleteProduct = React.useCallback(async (ind) => {
    if (window.confirm('Are you sure you want to delete this product? \nNote: this action cannot be undone. ')) {
      try {
        setLoading(true);
        const resp = await API.del('ModuplayAPI', '/admin/products', {
          body: {
            code: products[ind].code
          }
        });
        if (products[ind].gltf_object_key)
          await Storage.remove(products[ind].gltf_object_key);
        if (products[ind].usdz_object_key)
          await Storage.remove(products[ind].usdz_object_key);
        if (Array.isArray(products[ind].bin_img_object_keys)) {
          for (const k of products[ind].bin_img_object_keys) {
            await Storage.remove(k);
          }
        }
        console.log(resp);
        const newProducts = products.slice();
        newProducts.splice(ind, 1);
        setProducts(newProducts);
      } catch (err) {
        alert(err?.response?.data?.message || err.message || 'An unknown error occured.');
      } finally {
        setLoading(false);
      }
    }
  }, [products]);


  const handleSaveProduct = async () => {
    try {
      setLoading(true);
      const reqBody= {
        code: newCode,
        name: newName, 
        category_id: newCategoryID, 
        gltf_object_key: newGltfKey, 
        usdz_object_key: newUsdzKey, 
        bin_img_object_keys: newBinImgKeys, 
        prod_page_url: newProdPageUrl , 
        thumbnail_url: newThumbnailUrl, 
        price: newPrice
      };
      console.log(reqBody);
      console.log(editInd);

      if (editInd >= 0) {//editmode
        const result = await API.patch('ModuplayAPI', '/admin/products', {
          body: reqBody
        });

        console.log(result);

        const newProductsArr = products.slice();
        newProductsArr[editInd] = result;

        setProducts(newProductsArr);
      } else {
        const result = await API.put('ModuplayAPI', '/admin/products', {
          body: reqBody
        });

        console.log(result);
        setProducts(products.concat(result));
      }




      setDlOpen(false);





  } catch (err) {
      alert(err?.response?.data?.message || err.message || 'An unknown error occured.');
  } finally {
      setLoading(false);
  }

  }



  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  


  return (
    <ThemeProvider theme={theme}>
      { isAdmin(user) ? (<div className={classes.root}>
        <Dialog
          open={dlopen}
          onClose={handleDlClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">{"Add a new product ... "}</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3} >
              <Grid item sm={12}>
                <TextField
                  className={classes.marginbottom}
                  required
                  label="Product Code (SKU)"
                  variant="outlined"
                  fullWidth
                  value={newCode}
                  onChange={(ev) => { setNewCode(ev.target.value) }}
                  disabled={editInd >=0}
                />
                <TextField
                  className={classes.marginbottom}
                  label="Product name"
                  variant="outlined"
                  fullWidth
                  value={newName}
                  onChange={(ev) => { setNewName(ev.target.value) }}
                />
                <FormControl variant="outlined" className={classes.marginbottom} fullWidth>
                  <InputLabel id="select-category-outlined-label">Play system</InputLabel>
                  <Select
                    labelId="select-category-outlined-label"
                    id="select-category-outlined"
                    value={playmodeID}
                    onChange={(ev)=>setPlaymodeId(Number(ev.target.value))}
                    label="Playmode"
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    {playmodes.map((pmd)=>(
                      <MenuItem key={pmd.id} value={pmd.id}>{pmd.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.marginbottom} fullWidth>
                  <InputLabel id="select-category-outlined-label">Category</InputLabel>
                  <Select
                    labelId="select-category-outlined-label"
                    id="select-category-outlined"
                    value={newCategoryID}
                    onChange={(ev)=>setNewCategoryId(Number(ev.target.value))}
                    label="Category"
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    {categories.filter((c)=>(c.playmode_id === playmodeID)).map((c)=>(
                      <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <UploadFileFormControl prodCode={newCode} label="glTF Model File" id="gltf-fu-1" keyPrefix="gltf" objectKey={newGltfKey} setObjectKey={setNewGltfKey} accept=".gltf" multiple={false}/>
                <UploadFileFormControl prodCode={newCode} label="glTF Supporting File(s)" id="gltf-sup-1" keyPrefix="gltf" objectKey={newBinImgKeys} setObjectKey={setNewBinImgKeys} accept="image/*,.bin"  multiple/>
                <UploadFileFormControl prodCode={newCode} label="usdz Model File" id="usdz-fu-1" keyPrefix="usdz" objectKey={newUsdzKey} setObjectKey={setNewUsdzKey} accept=".usdz" multiple={false}/>

                <TextField
                  className={classes.marginbottom}
                  required
                  label="Product Page URL"
                  variant="outlined"
                  fullWidth
                  value={newProdPageUrl}
                  onChange={(ev) => { setNewProdPageUrl(ev.target.value) }}
                />
                <TextField
                  className={classes.marginbottom}
                  required
                  label="Thumbnail URL"
                  variant="outlined"
                  fullWidth
                  value={newThumbnailUrl}
                  onChange={(ev) => { setNewThumbnailUrl(ev.target.value) }}
                />
                <TextField
                  className={classes.marginbottom}
                  required
                  label="Price"
                  variant="outlined"
                  fullWidth
                  value={newPrice}
                  onChange={(ev) => { setNewPrice(Number(ev.target.value)) }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
             <Button color='primary' onClick={()=>setDlOpen(false)}>Cancel</Button>
            <Button color='primary' variant='contained' onClick={handleSaveProduct}>Save</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={loading}
          // onClose={handleDlClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">{"Loading ... "}</DialogTitle>
          <DialogContent dividers >
            <CircularProgress color="secondary" size={100} />
          </DialogContent>

        </Dialog>


        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackOpen}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert severity="success">
            {snackMsg}
          </Alert>
        </Snackbar>
        <CssBaseline />
        <Header />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
          <Button color='primary' variant='contained' onClick={handleAddNewProduct} className={classes.marginbottom}>Add product</Button>
          {(products.length>0) ? <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {/* code, name, category_id, gltf_object_key, usdz_object_key, bin_img_object_keys, prod_page_url, thumbnail_url, price */}
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Code (SKU)</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell> 
            <StyledTableCell>Category</StyledTableCell>
            <StyledTableCell>GLTF File</StyledTableCell>
            <StyledTableCell>USDZ File</StyledTableCell>
            <StyledTableCell>Price (A$)</StyledTableCell> 
            <StyledTableCell>Product Page URL</StyledTableCell>
            <StyledTableCell>Thumbnail</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((prod,ind) => (
            <StyledTableRow key={prod.code}>              
            <StyledTableCell >
            <IconButton aria-label="delete" className={classes.marginBtn} onClick={()=>handleDeleteProduct(ind)} >
              <DeleteIcon />
             </IconButton>
             <IconButton aria-label="Edit" className={classes.marginBtn} onClick={()=>handleEditProduct(ind)}>
              <EditIcon />
             </IconButton>
          </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {prod.code}
              </StyledTableCell>
              <StyledTableCell>{prod.name}</StyledTableCell>
              <StyledTableCell>{categories.find((c) => (c.id === prod.category_id)).name}</StyledTableCell>
              <StyledTableCell>{prod.gltf_object_key}</StyledTableCell>
              <StyledTableCell>{prod.usdz_object_key}</StyledTableCell>
              <StyledTableCell>{prod.price}</StyledTableCell> 
              <StyledTableCell><a href={prod.prod_page_url} target='_blank' rel='noopener noreferrer'>Test</a> </StyledTableCell>
              <StyledTableCell><img alt={prod.thumbnail_url} src={prod.thumbnail_url || 'https://dummyimage.com/200x200/fff/aaa'} style={{ height: '50px', width: '50px'}}/> </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> : <Typography>No prodcuts to show.</Typography> }
    </Container>
        </main>
      </div>) : <Typography> Not Authorised. </Typography> }
    </ThemeProvider>
  );
}